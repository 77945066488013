import { Radar } from 'lucide-react'
import { SPONSORS } from '#app/sponsors.constants.ts'

export const Sponsors = () => {
	return (
		<section id="sponsors" className="container pt-24 sm:py-32">
			<h2 className="text-md mb-8 text-center font-bold text-primary lg:text-xl">
				Sponsors
			</h2>

			<div className="flex flex-wrap items-center justify-center gap-4 md:gap-8">
				{SPONSORS.map(({ sponsorName }) => (
					<div
						key={sponsorName}
						className="flex items-center gap-1 text-muted-foreground/60"
					>
						<span>
							<Radar size={34} />
						</span>
						<h3 className="text-xl  font-bold">{sponsorName}</h3>
					</div>
				))}
			</div>
		</section>
	)
}
