import { Link } from '@remix-run/react'
import { Button, buttonVariants } from '../ui/button.tsx'
import { HeroCards } from './herocards.tsx'

export const Hero = () => {
	return (
		<section className="container grid place-items-center gap-10 py-20 md:py-32 lg:grid-cols-2">
			<div className="space-y-6 text-center lg:text-start">
				<main className="text-5xl font-bold md:text-6xl">
					<h1 className="inline">
						<span className="inline bg-gradient-to-r from-[#D5B066]  to-[#f0d398] bg-clip-text text-transparent">
							Indian
						</span>{' '}
						Amateur
					</h1>{' '}
					<h2 className="inline">
						<span className="inline bg-gradient-to-r from-[#001837] to-[#04397d] bg-clip-text text-transparent">
							Golf
						</span>{' '}
						Tour
					</h2>
				</main>

				<p className="mx-auto text-xl text-muted-foreground md:w-10/12 lg:mx-0">
					Play competitive golf while having fun and making new friends.
				</p>

				<div className="space-y-4 md:space-x-4 md:space-y-0">
					<Link to={'/login'}>
						<Button className="w-full md:w-1/3">Get Started</Button>
					</Link>
					<Link to={'/events'}>
						<div
							className={`w-full md:w-1/3 ${buttonVariants({
								variant: 'outline',
							})}`}
						>
							Events
						</div>
					</Link>
				</div>
			</div>

			{/* Hero cards sections */}
			<div className="z-10">
				<HeroCards />
			</div>
		</section>
	)
}
