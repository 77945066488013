import { Hero } from './hero.tsx'
import { Sponsors } from './sponsors.tsx'

export default function LandingPage() {
	return (
		<div>
			<Hero />
			<Sponsors />
		</div>
	)
}
