import { Link } from '@remix-run/react'
import { type JSX } from 'react/jsx-runtime'
import Post from './post.tsx'
import { Button } from './ui/button.tsx'

type Props = {
	posts: any
}

const Posts = ({ posts }: Props) => {
	return (
		<>
			{posts?.map(
				(
					post: JSX.IntrinsicAttributes & {
						id: string
						createdAt: string
						updatedAt: string
						subject: string
						content: string
						author: string
						userId: string
						user: any
					},
				) => <Post {...post} key={post.id} />,
			)}
			{posts?.length == 0 && <div>No posts</div>}
			{posts?.length == 25 && (
				<section className="bg-white p-2 antialiased dark:bg-gray-900 lg:py-4">
					<div className="flex justify-center">
						<Link to={'/posts'}>
							<Button>View all posts</Button>
						</Link>
					</div>
				</section>
			)}
		</>
	)
}

export default Posts
