import { type MetaFunction, type DataFunctionArgs } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'
import LandingPage from '#app/components/landing/main.tsx'
import Posts from '#app/components/posts.tsx'
import { REGISTRATION_2024 } from '#app/events.constants.ts'
import { getUserId } from '#app/utils/auth.server.ts'
import { prisma } from '#app/utils/db.server.ts'
import { loginAction } from '../_auth+/login.tsx'

export async function loader({ request }: DataFunctionArgs) {
	const userId = await getUserId(request)
	let isUserRegistered = true
	let allowRegistration = false

	if (userId) {
		const user = await prisma.user.findFirst({
			select: {
				id: true,
				firstName: true,
				lastName: true,
				username: true,
				createdAt: true,
				image: { select: { id: true } },
				registeredFor2024: true,
			},
			where: { id: userId },
		})

		isUserRegistered = !!user?.registeredFor2024
		const count = await prisma.user.count({
			where: {
				registeredFor2024: true,
			},
		})
		allowRegistration = count < REGISTRATION_2024.totalSlots
	}
	const posts = await prisma.post.findMany({
		take: 25,
		orderBy: {
			createdAt: 'desc',
		},
		include: {
			user: {
				select: {
					firstName: true,
					lastName: true,
					username: true,
					image: {
						select: {
							id: true,
						},
					},
					roles: true,
				},
			},
		},
	})

	return {
		isLoggedIn: !!userId,
		isUserRegistered,
		allowRegistration,
		posts,
	}
}

export async function action(actionArg: DataFunctionArgs) {
	return loginAction(actionArg)
}

export const meta: MetaFunction = () => [
	{ title: 'IAGT' },
	{
		name: 'description',
		content: 'Play competitive golf while having fun and making new friends',
	},
	{
		property: 'og:title',
		content: 'IAGT',
	},
	{
		property: 'og:type',
		content: 'website',
	},
	{
		property: 'og:video',
		content: '/intro-video.mp4',
	},
	{
		property: 'og:image',
		content: '/img/logo-1.png',
	},
	{
		property: 'og:url',
		content: 'https://iagt.golf',
	},
]

export default function Index() {
	const { isLoggedIn, posts } = useLoaderData<typeof loader>()
	return (
		<main>
			{isLoggedIn ? (
				<>
					<h2 className="text-center text-2xl font-extrabold tracking-tight text-gray-900 dark:text-white sm:mb-4 sm:text-4xl">
						Posts
					</h2>
					<div className="mx-auto mb-2 grid max-w-screen-xl justify-center px-5 sm:gap-4 xl:grid-cols-2">
						<Posts posts={posts} />
					</div>
				</>
			) : (
				<LandingPage />
			)}
		</main>
	)
}
