import { Link } from '@remix-run/react'
import { Badge, Check } from 'lucide-react'
import { Avatar, AvatarImage, AvatarFallback } from '../ui/avatar.tsx'
import { Button } from '../ui/button.tsx'
import {
	Card,
	CardHeader,
	CardTitle,
	CardDescription,
	CardContent,
	CardFooter,
} from '../ui/card.tsx'

export const HeroCards = () => {
	return (
		<div className="relative hidden h-[500px] w-[700px] flex-row flex-wrap gap-8 lg:flex">
			{/* Testimonial */}
			<Card className="absolute -top-[15px] right-[100px] w-[340px] shadow-black/10 drop-shadow-xl dark:shadow-white/10">
				<CardHeader className="flex flex-row items-center gap-4 pb-2">
					<Avatar>
						<AvatarImage
							alt="Gautam Jha"
							src={'/img/organizers/gautam.jpg'}
							className="object-cover"
						/>
						<AvatarFallback>GJ</AvatarFallback>
					</Avatar>

					<div className="flex flex-col">
						<CardTitle className="text-lg">Gautam Jha</CardTitle>
						<CardDescription>@twitwithgautam</CardDescription>
					</div>
				</CardHeader>

				<CardContent>
					Join IAGT to improve your game and meet great people at the same time!
				</CardContent>
			</Card>
			{/* Pricing */}
			<Card className="absolute right-[100px] top-[150px] w-72  shadow-black/10 drop-shadow-xl dark:shadow-white/10">
				<CardHeader>
					<CardTitle className="item-center flex justify-between">
						2024 Registration
						<Badge className="text-sm text-primary"></Badge>
					</CardTitle>
					<div>
						<span className="text-3xl font-bold">$150</span>
					</div>
				</CardHeader>

				<CardContent>
					<Link to={'/events'}>
						<Button className="w-full">More Info</Button>
					</Link>
				</CardContent>

				<hr className="m-auto mb-4 w-4/5" />

				<CardFooter className="flex">
					<div className="space-y-4">
						{[
							'Join 120 members',
							'Access 7 courses',
							'Members WhatsApp Community',
						].map((benefit: string) => (
							<span key={benefit} className="flex">
								<Check className="text-green-500" />{' '}
								<h3 className="ml-2">{benefit}</h3>
							</span>
						))}
					</div>
				</CardFooter>
			</Card>
		</div>
	)
}
